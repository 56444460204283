import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import g from 'glamorous'
import { Helmet } from 'react-helmet'
import ProjectContent from '../components/ProjectContent'

export default ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>
          {post.frontmatter.title} | Valerie Pasquiou Interiors & Design
        </title>
        <meta
          name="description"
          content={`Discover our ${post.frontmatter.service} ${
            post.frontmatter.title
          } project: a ${post.frontmatter.sqft} ${
            post.frontmatter.building
          } in ${post.frontmatter.location}.`}
        />
      </Helmet>
      <Wrapper>
        <Col>
          <Fixter>
            <H1>{post.frontmatter.title}</H1>
            <Info>{post.frontmatter.location}</Info>
            <Info>{post.frontmatter.building}</Info>
            <Info>{post.frontmatter.sqft}</Info>
            <Info>{post.frontmatter.service}</Info>
            <Info>{post.frontmatter.infos}</Info>
          </Fixter>
        </Col>

        <ProjectContent html={post.html} />
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        cat
        location
        building
        sqft
        service
        infos
      }
      html
    }
  }
`

const Wrapper = g.div({
  marginTop: `90px`,
  display: `grid`,
  gridTemplateColumns: `26% 72% `,
  columnGap: `20px`,
  '@media(max-width: 768px)': {
    marginTop: `0px`,
    display: `block`,
    padding: `50px 15px 0`,
  },
})

const Col = g.div({
  width: `100%`,
})

const Fixter = g.div({
  position: `fixed`,
  maxWidth: `300px`,
  '@media(max-width: 768px)': {
    position: `relative`,
    marginBottom: `10px`,
  },
})

const H1 = g.h1({
  fontSize: `2.9em`,
  lineHeight: `1em`,
  marginBottom: `.3em`,
  '@media(max-width: 768px)': {
    fontSize: `1.8em`,
    marginTop: `0`,
    marginBottom: `.2em`,
  },
})

const Info = g.div({
  fontSize: `.89em`,
  marginBottom: `.1em`,
  '@media(max-width: 768px)': {
    fontSize: `.85em`,
    marginBottom: `0`,
    lineHeight: `1.65em`,
    paddingLeft: `1px`,
  },
})
