import React from 'react'
import Slider from 'react-slick'
import { css } from 'glamor'
import g from 'glamorous'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { MdClose } from 'react-icons/md'
import { MdNavigateNext } from 'react-icons/md'
import { MdNavigateBefore } from 'react-icons/md'

export default class Slideshow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    //  this.slider.slickGoTo(3);
  }

  handleClose() {
    this.setState({
      showSlideshow: false,
    })
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      height: 600,
      adaptiveHeight: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    }
    return (
      <Wrapper className="wrapperSlider">
        <button onClick={this.props.handler} {...cssCloseButton}>
          <MdClose />
        </button>
        <Slider
          ref={slider => (this.slider = slider)}
          {...settings}
          adaptiveHeight={false}
          slickGoTo={this.state.currentSlide}
          initialSlide={this.props.initialSlide}
          dots="false"
        >
          {this.props.arrayImg.map(function(item) {
            return (
              <div key={item.src}>
                <img src={item.src} srcset={item.srcset} alt={item.alt} />
              </div>
            )
          })}
        </Slider>
      </Wrapper>
    )
  }
}

let bounce = css.keyframes({
  '0%': { opacity: 0 },
  '60%': { opacity: 0.8 },
  '100%': { opacity: 1 },
})

const Wrapper = g.div({
  animation: `${bounce} .3s`,
  position: `fixed`,
  width: `100vw`,
  height: `100vh`,
  top: `0`,
  left: `0`,
  backgroundColor: `#faf9f9`,
  zIndex: 20,
  display: `flex`,
  alignItems: `center`,
})

css.global('.wrapperSlider .slick-slider', {
  width: `1075px`,
  maxWidth: `90vw`,
  maxHeight: `90vh`,
  margin: `auto`,
})

css.global('.wrapperSlider .slick-slide img', {
  maxHeight: `80vh`,
  margin: `0 auto`,
  padding: `0 10px`,
})

const cssCloseButton = css({
  backgroundColor: `transparent`,
  position: `absolute`,
  top: 0,
  right: 0,
  border: `none`,
  fontSize: `48px`,
  padding: `.5em`,
  zIndex: 21,
  transition: `all 0.12s ease-in-out`,
  ':hover': {
    opacity: `.6`,
    transition: `all 0.2s ease-in-out`,
    fontSize: `50px`,
  },
  ':focus': {
    outline: `none`,
    opacity: `.6`,
    transition: `all 0.2s ease-in-out`,
  },
})

css.global('.wrapperSlider .slick-next ', {
  width: `60px !important`,
  height: `60px !important`,
  right: `-64px`,
  color: `#1f1f1f`,
  fontSize: `45px`,
})

css.global('.wrapperSlider .slick-next:hover ', {
  color: `#1f1f1f`,
  opacity: `.8`,
  fontSize: `46px`,
})

css.global('.wrapperSlider .slick-prev ', {
  width: `60px !important`,
  height: `60px !important`,
  left: `-64px`,
  color: `#1f1f1f`,
  fontSize: `45px`,
})

css.global('.wrapperSlider .slick-prev:hover ', {
  color: `#1f1f1f`,
  opacity: `.8`,
  fontSize: `46px`,
})

css.global('.wrapperSlider .slick-next:before', {
  display: `none`,
})

css.global('.wrapperSlider .slick-prev:before', {
  display: `none`,
})

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <div css={{ color: `black`, fontSize: `64px` }}>
        <MdNavigateNext />
      </div>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <div css={{ color: `black`, fontSize: `64px` }}>
        <MdNavigateBefore />
      </div>
    </div>
  )
}
