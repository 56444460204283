import React from 'react'
import Slideshow from '../components/Slideshow'

export default class AvantApresContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imgSrc: '',
      arrayImg: [],
      showSlideshow: false,
      currentSlide: 0,
    }
    this.showImage = this.showImage.bind(this)
    this.showSlideShow = this.showSlideShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handler = this.handler.bind(this)
  }

  componentDidMount() {
    const parser = new DOMParser()
    const htmlDoc = parser.parseFromString(this.props.html, 'text/html')
    const images = htmlDoc.getElementsByTagName('img')
    const array = [].slice.call(images)

    this.setState({
      arrayImg: array,
    })

    array.forEach(function(item) {})
  }

  showSlideShow() {
    this.setState({
      showSlideshow: true,
    })
  }

  handleClose() {
    this.setState({
      showSlideshow: false,
    })
  }

  handler(e) {
    e.preventDefault()
    this.setState({
      showSlideshow: false,
    })
  }

  showImage(e) {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      e.stopPropagation()
    } else {
      e.stopPropagation()

      let target = e.target
      console.log(target)

      const index = this.state.arrayImg.findIndex(x => x.src === target.src)
      console.log(index)
      this.setState({
        currentSlide: index,
        showSlideshow: true,
      })
    }
  }

  render() {
    return (
      <div>
        <div>
          {this.state.showSlideshow && (
            <Slideshow
              arrayImg={this.state.arrayImg}
              initialSlide={this.state.currentSlide}
              handler={this.handler}
            />
          )}

          <div
            dangerouslySetInnerHTML={{ __html: this.props.html }}
            onClick={this.showImage}
            className="project-page"
          />
        </div>
      </div>
    )
  }
}
